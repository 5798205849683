import React from 'react';
import Helmet from 'react-helmet';

import Layout from '../../layouts/SimplePage';
import FirstScreenSection from '../../components/FirstScreenSection';
import GradesSection from '../../components/GradesSection';
import Calculator from '../../components/Calculator';
import Testimonials from '../../components/Testimonials';
import OurFeaturesSection from '../../components/OurFeaturesSection';
import { SeoTextSection, SeoTextHeading, SeoTextContent } from '../../components/SeoText';
import CTA from '../../components/CTA';


const PhilosophyWritingServicePage = () => (
  <Layout
    pageWrapperClassName="philosophy-writing-service-page"
    headerClassName="header_white-bg"
  >

    <Helmet
      title="Philosophy Essay Writing Service - Write My Philosophy Essay"
      meta={[
        {
          name: 'description',
          content: 'TakeAwayEssay.com 📝 is a Philosophy Essay Writing Service with highly professional writers and guarantees for timely delivery. Thousands of satisfied clients have left positive reviews about the service’s 24/7 customer support and quality of Writing Services For Philosophy Essay.',
        },

        {
          property: 'og:title',
          content: 'Custom Philosophy Essay Writing Service for the Best Result | TakeAwayEssay.com',
        },
        {
          property: 'og:description',
          content: 'Custom philosophy essay writing service from specialists at affordable prices. Order your philosophy essay from a reputable provider to guarantee the needed quality and compliance with your requirements. Urgent deadlines. Attentive customer support.',
        },
        { property: 'og:site_name', content: 'Takeawayessay.com' },
        { property: 'og:type', content: 'website' },
        { property: 'og:image', content: 'https://takeawayessay.com/open-graph-img.jpg' },
      ]}
      link={[
        { rel: 'canonical', href: 'https://takeawayessay.com/philosophy-writing-service.html' },
      ]}
    />

    <FirstScreenSection containerClassName="first-screen-section--seo">
      <h2 className="first-screen-section__title first-screen-section__title_seo">
        Philosophy essays
        <br className="first-screen-section__title__br" />
        {' '}
        harsh no longer
      </h2>
      <p className="first-screen-section__heading first-screen-section__heading_seo">
        To write or not to write —
        <br className="first-screen-section__heading__br" />
        {' '}
        that is not the question anymore. Just buy it!
      </p>
    </FirstScreenSection>

    <GradesSection />
    <Calculator />
    <Testimonials nameOfPageArray="home" />
    <OurFeaturesSection />

    <SeoTextSection>
      <SeoTextHeading>
        Write my philosophy essay: philosophy essay writing service
      </SeoTextHeading>

      <SeoTextContent>
        <div className="seo-text-section__text__css-half-column">
          <p>
            We know that being a student at college or university can be stressful. Why so? Because
            of so many assignments! This situation is even worse if you are majoring in philosophy.
            Then, you have to write many essays and papers throughout a term.
          </p>
          <p>
            This is why we want to help you.{' '}
            <strong>
              We draw your attention to our custom philosophy essay writing service. Our service is
              straightforward; we help you to write a custom essay or paper for money.{' '}
            </strong>
            Well, there are no free services nowadays, right?
          </p>
          <p>
            But our philosophy essay writing service is cheap. Most students can afford to buy an
            assignment from us. Our rates are low but the quality of our writing is top. Can you get
            a better deal somewhere else? We doubt it. The best essay for an affordable price, this
            is a bargain!
          </p>
          <p>
            You can count on our philosophy essay writing service to be one of the best students in
            your class. Our professional writers can write any type of paper you need, from a
            research paper to your final dissertation. What’s more, they will write papers that
            guarantee excellent grades. Interested? Read on.
          </p>
          <h2 className="h3">You can buy philosophy essay online easily on our website</h2>
          <p>
            You may be wondering where you can buy philosophy essay. It is easy, buy philosophy
            essay online! You can place an order directly on our website. All you have to do is fill
            in an online form with the information about your assignment. Don’t worry. You don’t
            have to supply any private information about you or your college.
          </p>
          <p>
            After submitting your order form, a professional writer will start working on your
            assignment. He or she will work fast and efficiently. Even faster if the order is
            urgent! Thus, we can summarize the characteristics that make our service the best on the
            market:
          </p>
          <ul className="dot-list">
            <li>
              Supreme quality essays at prices any student can pay. You may wonder how we manage to
              keep our prices low. Well, we don’t spend money on expensive advertisements.
            </li>
            <li>
              You have the certainty that the helper writing your paper is an expert in the
              corresponding subject matter. This ensures a professional help with achieving a top
              grade for the essay you paid for.
            </li>
            <li>
              Timely delivery. We know that a late submission cannot get a good grade. This is why
              our writers work around the clock to complete all the assignments on time.
            </li>
            <li>
              You get an original and authentic essay. Our writers know that plagiarism is not
              acceptable in academic writing. Hence, they never reuse any text they have written
              before. Moreover, we check every essay for plagiarism before we deliver them to our
              customers. We use special software to scan the texts.
            </li>
          </ul>
          <p>
            These are the reasons our services are highly rated among students of the best colleges
            and universities. We are sure you cannot find a company that offers the same quality for
            such low prices.
          </p>
          <h2 className="h3">
            Send a “write my philosophy essay” message and we will lend a helping hand
          </h2>
          <p>
            When you wonder “who can help me with my essay?” you know whom you should ask for help.
            Send us a “write my philosophy essay” message, and one of our experts will lend a
            helping hand. We hire writers that hold advanced university degrees in philosophy.
            Hence, you know what you pay for with our service.
          </p>
          <p>
            You shouldn’t worry about using the essays we have for sale as sources of inspiration or
            information. Our services are legit. When you purchase an essay from us, you are
            licensed to use it for references and as a source of new knowledge. Thus, feel free to
            ask “do my philosophy essay;” we will do it as you require.
          </p>
          <h2 className="h3">
            If you pay for philosophy essay, make sure you get the best for your money
          </h2>
          <p>
            So, if you have decided to pay for an essay, use our custom philosophy essay writing
            service. You won’t regret it. We have more than a decade of experience helping students.
            This is our main motivation. This is why we do our best to satisfy all our clients. The
            most important thing for us is your academic success.
          </p>
          <p>
            We are also very discreet. We know that most students prefer to remain anonymous when
            they use our services. Hence, our service contract is something like a non-disclosure
            agreement too. Your information is confidential as we apply the GDPR and our own privacy
            policy.
          </p>
          <p>
            As you can see, we are your best option to get custom philosophy essays online. We do
            our best to guarantee your satisfaction!
          </p>
        </div>
      </SeoTextContent>
    </SeoTextSection>

    <CTA btnText="Order your paper">
      Placing an order now would indeed be an ingenious idea.
      <br />
      {' '}
      Eureka!
    </CTA>

  </Layout>
);

export default PhilosophyWritingServicePage;
